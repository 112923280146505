import React, { useEffect, useState } from 'react';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { GlobalStyles } from './styles/GlobalStyles';

import RouterComponent from './components/RouterComponent';
import Context, { IContext } from './context/Context';

window.document.title = import.meta.env.VITE_EMPRESA || 'Escola Confraria da Dança';

function App() {
  const [isLoadingOverlay, setIsLoadingOverlay] = useState<boolean>(false);

  const contextValues: IContext = {
    isLoadingOverlay, setIsLoadingOverlay
  }

  return (
    <Context.Provider value={contextValues}>
      <GlobalStyles />
      <ToastContainer
        style={{ zIndex: 999999999999 }}
        position='top-right'
        theme='colored'
      />
      <LoadingOverlayWrapper
        active={isLoadingOverlay}
        spinner
        text='Aguarde...'
      >
        <RouterComponent />
      </LoadingOverlayWrapper>
    </Context.Provider>
  )
}

export default App;
