import React, { forwardRef } from 'react';
import { Container } from './styles';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeHolder: string;
  animated?: boolean;
}

// Usar `forwardRef` para permitir o recebimento de uma `ref`
const FormInput = forwardRef<HTMLInputElement, IProps>(
  ({ placeHolder, animated, ...rest }, ref) => (
    <Container $animated={animated} $temPlaceHolder={Boolean(placeHolder)}>
      <input ref={ref} {...rest} placeholder=" " />
      {placeHolder && <span>{placeHolder}</span>}
    </Container>
  )
);

export default FormInput;
