import { createContext } from 'react';

export interface IContext {
  isLoadingOverlay: boolean;
  setIsLoadingOverlay: (isLoadingOverlay: boolean) => void;
}

const Context = createContext<IContext>({} as IContext);

export default Context;
