import styled from 'styled-components';

export const CustomButton = styled.button`
  width: 80%;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background-color: #232121;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px 0;

  &:hover {
    background-color: #BB2D30;
  }

  &:focus {
    outline: 2px solid #333;
  }
`;
