import styled, { keyframes } from 'styled-components';
import { zoomIn } from 'react-animations';

const animationType = keyframes`${zoomIn}`;

interface IProps {
  $animated?: boolean;
  $temPlaceHolder?: boolean;
}

export const Container = styled.div<IProps>`
  margin: 10px 0;
  position: relative;
  width: 100%;
  animation: 0.5s ${({ $animated }) => ($animated ? animationType : 'none')};

  input {
    width: 100%;
    ${({ $temPlaceHolder }) => ($temPlaceHolder ? 'padding: 20px 15px 10px 15px;' : 'padding: 15px 15px 15px 15px;')};
    outline: none;
    border: none;
    border-radius: 5px;
    background-color: #f1f1f1;
    color: #333;
    font-size: 14px;
    font-weight: 550;
    transition: 0.3s ease-in-out;
    box-shadow: 0 0 0 5px transparent;
    min-width: 180px;
  }

  input:hover,
  input:focus {
    box-shadow: 0 0 0 2px #333;
  }

  span {
    position: absolute;
    left: 0;
    top: 0;
    padding: 13px 15px;
    color: #333;
    font-size: 14px;
    font-weight: 600;
    text-shadow: -1px -1px 0 #f1f1f1, 1px -1px 0 #f1f1f1, -1px 1px 0 #f1f1f1,
      1px 1px 0 #f1f1f1;
    transition: 0.3s ease-in-out;
    pointer-events: none;
  }

  input:not(:placeholder-shown) + span,
  input:focus + span {
    transform: translateY(-12px) translateX(-5px) scale(0.95);
  }
`;
