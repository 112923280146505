import React from 'react';
import { CustomButton } from './styles';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

export default function Button({ children, ...rest }: IProps) {
  return (
    <CustomButton {...rest}>
      {children}
    </CustomButton>
  );
}
