import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 99999;

  div {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;

    @media screen and (max-width: 767px){
      justify-content: center;
    }
  }

  img {
    margin: 5px;
    width: 10%;
  }

  hr {
    width: 100%;
    border-width: 1px;
    border-color: #ccc;
  }

  @media screen and (max-width: 767px){
    img {
      width: 30%;
    }
  }
`;
