import React, { useContext, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  // useNavigate,
  // useLocation
} from 'react-router-dom';
// import Context, { IContext } from '../../context/Context';
// import { toast } from 'react-toastify';
// import Cookies from 'js-cookie';

import ErrorPage from '../../pages/ErrorPage';

import Header from '../Header';
import Home from '../../pages/Home';

function LayoutFixo() {
  // const navigate = useNavigate();
  // const { pathname } = useLocation();
  // const { setUsuario }: IContext = useContext(Context);

  // function getCookies() {
  //   const usuarioCookie = Cookies.get('usuarioCookie');

  //   if (!usuarioCookie && pathname !== '/') {
  //     toast.warning('Necessario realizar o login');
  //     navigate('/');
  //     return;
  //   }

  //   if (!usuarioCookie) return;

  //   setUsuario(JSON.parse(usuarioCookie));
  // }

  // useEffect(() => {
  //   getCookies();
  // }, [pathname]);

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
}

export default function RouterComponent() {
  return (
    <>
      <Router>
        <Routes>
          <Route element={<LayoutFixo />}>
            <Route path='/' element={<Home />} />
            {/* <Route path='/professores' element={} /> */}
          </Route>
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </Router>
    </>
  );
}
