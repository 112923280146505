import styled, { keyframes } from 'styled-components';
import { zoomIn } from 'react-animations';

const animationType = keyframes`${zoomIn}`;

interface ITimeButton {
  $selected: boolean;
}

export const Container = styled.div`
  display: flex;
  /* justify-content: center; */
  align-content: center;
  flex-direction: column;
  min-height: 100dvh;
  width: 100dvw;
  background-color: #fff;
  text-align: center;

  h1 {
    margin: 10px 0;
  }
`;

export const SubContainer = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  width: 25%;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;

  h2 {
    margin: 10px 0;
  }

  @media screen and (max-width: 767px) {
    width: 80%;
  }
`;

export const SelectWrapper = styled.div`
  animation: 0.5s ${animationType};
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  position: relative;
  margin: 5px;
  width: 40%;
  align-self: center;

  @media screen and (max-width: 767px) {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #f1f1f1;
  color: #333;
  font-size: 14px;
  font-weight: 550;
  transition: 0.3s ease-in-out;
  box-shadow: 0 0 0 5px transparent;
  appearance: none;
  cursor: pointer;
  min-width: 180px;
  text-overflow: ellipsis;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 2px #333;
  }
`;

export const Label = styled.label`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

export const Option = styled.option`
  background-color: #ffffff;
  color: #333;
  font-size: 14px;
  padding: 15px;
  font-weight: 500;

  &:hover {
    background-color: #f1f1f1;
  }

  &:checked {
    background-color: #e0e0e0;
  }
`;

export const InputsContainer = styled.div`
  animation: 0.5s ${animationType};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DiaDaSemanaContainer = styled.div`
  animation: 0.5s ${animationType};
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 10px;

  h3 {
    text-transform: capitalize;
  }
`

export const TimeButtonContainer = styled.div`
  animation: 0.5s ${animationType};
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Duas colunas */
  gap: 10px;
  margin: 10px auto;
`;


export const TimeButton = styled.button<ITimeButton>`
  width: 100%;
  padding: 10px 25px;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background-color: #232121;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  ${({ $selected }) => ($selected ? 'background-color: #BB2D30;' : '')}

  &:hover {
    background-color: #BB2D30;
  }

  &:focus {
    outline: 2px solid #333;
  }
`;

export const AlertContainer = styled.form`
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  max-width: 500px;
  margin: auto;
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 16px;
`;

export const Message = styled.p`
  margin-bottom: 8px;
  font-size: 1.25rem;
`;

export const Caption = styled.p`
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 0.7rem;

  a {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const InputsContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonModal = styled.button`
  background-color: #000;
  color: #fff;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: bold;
  margin-left: 8px;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }
`;

