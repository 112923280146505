import React, { useContext } from 'react';
import { Container } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Header() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  function handleNavigate() {
    if (pathname === '/') return;

    navigate('/');
  }

  return (
    <Container>
      <div>
        <img src={'https://imagizer.imageshack.com/img923/6391/WRwshQ.png'} alt='logo' onClick={handleNavigate} />
      </div>
      <hr />
    </Container>
  );
}
